import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
  CFormLabel,
  CForm,
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CCollapse,
  CButton,
  CFormInput,
  CFormCheck,
  CCardTitle,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from '@coreui/react-pro'
import { Col, Row } from 'react-bootstrap'
import PaymentModal from '../ActivateGame/PaymentModal'
import CommonService from 'src/service/CommonService'
import ToastComponent from 'src/components/common/TaostComponent'

const SelectPlan = () => {
  const [visibleA, setVisibleA] = useState(true)
  const [visibleB, setVisibleB] = useState(false)
  const [dNoneClass, setDNoneClass] = useState('d-none')
  const [visible, setVisible] = useState(false)
  const [disabledBtn, setdisabledBtn] = useState(true)
  const [activateGameText, setActivateGameText] = useState('')
  const [activationInfo, setActivationInfo] = useState({})
  const [amount, setAmount] = useState(99)

  const [invoiceLink, setInvoiceLink] = useState(true)

  // const accountInfo = JSON.parse(localStorage.getItem('admin'))
  useEffect(() => {
    CommonService.getGameActivateInfo(1)
      .then((res) => {
        if (res.status === 200) {
          setActivationInfo(res.getGameActivationInfo)
          if (res.getGameActivationInfo) {
            // console.log('activationInfo', activationInfo)
            const invoiceURL = JSON.parse(res.getGameActivationInfo.transaction_information)
            setInvoiceLink(invoiceURL.receipt_url)
          }

          if (
            res.game_info?.game_name &&
            res.game_info?.game_name &&
            res.game_info?.timezone &&
            res?.round_info
          ) {
            setdisabledBtn(false)
            setActivateGameText('Activate Game')
          }
        }
      })
      .catch((e) => {
        console.log('E=> ', e)
        // ToastComponent('Something went wrong. Please try again.', 'error')
      })
  }, [activateGameText])
  const [isChecked, setIsChecked] = useState(true)
  const handleActiveTerm = (event) => {
    if (event.target.checked) {
      setIsChecked(false)
    } else {
      setIsChecked(true)
    }
  }
  return (
    <>
      {' '}
      <CCard className="mb-4">
        <CCardHeader>
          <strong>Starter Plan</strong>
        </CCardHeader>
        <CCardBody>
          {/* <CButton
            onClick={() => {
              setVisibleA(!visibleA)
              setVisibleB(false)
            }}
          >
            Starter Plan{' '}
          </CButton>{' '}
          <CButton
            color="success"
            onClick={() => {
              setVisibleB(!visibleB)
              setVisibleA(false)
            }}
          >
            Partner Plan
          </CButton> */}
          One-time fees for the activation of the game applies.
          <br></br>
          <br></br>
          <strong>*Included*</strong>
          <ul>
            <li>Customize game setup</li>
            <li>Access to all features</li>
            <li>Personal game account hub</li>
            <li>Unlimited player records</li>
            <li>Unlimited members</li>
            <li>Manage own score updates</li>
            <li>30-min setup call</li>
            <li>Ticket Support</li>
          </ul>
          {/* <CFormCheck
            inline
            id="inlineCheckbox1"
            value="option1"
            label="Discount Code?"
            onChange={handlePromoCode}
          />{' '} */}
          {/* promo Code section */}
          <CForm className={`row mt-3 g-3 ${dNoneClass}`}>
            <div className="col-auto">
              <CFormLabel htmlFor="inputPassword2" className="visually-hidden">
                code
              </CFormLabel>
              <CFormInput type="text" id="inputPassword2" placeholder="Code" />
            </div>
            <div className="col-auto">
              <CButton type="submit" className="mb-3">
                Apply
              </CButton>
            </div>
          </CForm>
          <Row>
            <Col>
              <p>
                Accept{' '}
                <a
                  href="https://cricket.myclubtap.com/terms-of-use"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of use
                </a>{' '}
              </p>
              <div className="form-group mb-3">
                <CFormCheck
                  inline
                  id="inlineCheckbox1"
                  value="option1"
                  label="I agree to the terms of use"
                  onChange={handleActiveTerm}
                />
              </div>
            </Col>
          </Row>
          {activationInfo?.is_paid !== 1 && (
            <CButton onClick={() => setVisible(!visible)} disabled={isChecked}>
              Pay Now (${amount})
            </CButton>
          )}
          {activationInfo?.is_paid === 1 && <CButton>Paid</CButton>}
          <br></br>
          <CRow>
            <CCol sm={6} md={12} xs={12}>
              <CCollapse visible={visibleB}>
                <CCard className="mt-3">
                  <CCardBody>
                    <CCardTitle>Partner Plan ($0)</CCardTitle>
                    Best if you want a one-time fees for the game and want to administer your own
                    game.
                    <ul className="mt-2">
                      <li>Customize game setup</li>
                      <li>Access to all features</li>
                      <li>Personal game account hub</li>
                      <li>Unlimited player records</li>
                      <li>Unlimited members</li>
                      <li>Score updates included</li>
                      <li>60-min setup call</li>
                      <li>Zoom/Email/Ticket Support</li>
                    </ul>
                    <CForm className={`row mt-3`}>
                      <CCardTitle className="mt-2">
                        Set Game Pass Fees{' '}
                        <small>
                          {' '}
                          <em> (required)</em>
                        </small>
                      </CCardTitle>
                      Team entry fee collection on platform
                      <ul className="mt-2">
                        <li>Available for clubs/leagues in Australia only</li>
                        <li>Processing Fees: 40%, Club/League: 60%</li>
                        <li>Min Amount ($10) & Max Amount ($100)</li>
                        <li>Fees in Australian Dollars (AUD) only</li>
                      </ul>
                      <CRow className="">
                        <div className="col-md-6">
                          {' '}
                          <CFormInput
                            type="number"
                            id="inputPassword2"
                            min={10}
                            max={100}
                            placeholder="Set Game Pass Fees*"
                          />
                        </div>
                      </CRow>
                      <CRow className="mt-4">
                        <div className="col-md-6">
                          {' '}
                          <CButton>Save </CButton>{' '}
                        </div>{' '}
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCollapse>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      {activationInfo && activationInfo.is_paid === 1 && (
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Transactions</strong>
          </CCardHeader>

          <CCardBody>
            <CTable>
              <CTableHead>
                <CTableRow>
                  <CTableHeaderCell scope="col">SN</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Invoice</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Amount</CTableHeaderCell>
                  <CTableHeaderCell scope="col">Paid On</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                <CTableRow>
                  <CTableHeaderCell scope="row">1</CTableHeaderCell>
                  <CTableDataCell>
                    {/* <button onClick={handleDownload}>Download Invoice</button> */}
                    <a href={`${invoiceLink}`} target="_blank" rel={'noreferrer'}>
                      Invoice
                    </a>
                  </CTableDataCell>
                  <CTableDataCell>${activationInfo?.fee}</CTableDataCell>
                  <CTableDataCell>
                    {' '}
                    {moment(activationInfo?.created_at).format('D.MM.YYYY h:mm A')}
                  </CTableDataCell>
                </CTableRow>
              </CTableBody>
            </CTable>
          </CCardBody>
        </CCard>
      )}
      <PaymentModal
        visible={visible}
        setVisible={setVisible}
        activationInfo={activationInfo}
        setActivationInfo={setActivationInfo}
        amount={amount}
        type={1}
        noOfFixture={0}
      />
    </>
  )
}

export default SelectPlan
