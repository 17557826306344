import {
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CFormTextarea,
  CLoadingButton,
  CRow,
} from '@coreui/react-pro'
import ReactQuill from 'react-quill'
import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AboutService from 'src/service/AboutService'
import ToastComponent from 'src/components/common/TaostComponent'
const EditForm = (props) => {
  const [featureDetail, setFeatureDetail] = useState()
  const [longDescription, setLongDescription] = useState()
  useEffect(() => {
    if (props.selectedFeatureId === props.featureId) {
      AboutService.getAboutDetail(props.featureId).then((res) => {
        if (res.status === 200) {
          setFeatureDetail(res.data)
          setLongDescription(res?.data.text2)
        }
      })
    }
  }, [props])

  const [loader, setLoader] = useState(false)
  const validationSchema = Yup.object().shape({
    type: Yup.string().required('Type is required'),
    text1: Yup.string().required('text1 is required'),
  })
  const formik = useFormik({
    initialValues: {
      text1: featureDetail?.text1,
      text2: featureDetail?.text2,
      type: featureDetail?.type,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data, actions) => {
      data.featureId = props.featureId
      data.text2 = longDescription
      setLoader(true)
      AboutService.editAbout(data)
        .then((res) => {
          if (res.status === 200) {
            props.fetchData(
              props.activePage,
              props.columnFilter,
              props.columnSorter,
              props.itemsPerPage,
            )
            ToastComponent(res.message, 'success')
            setLoader(false)
          } else {
            setLoader(false)
            ToastComponent('something went wrong.', 'error')
          }
        })
        .catch((e) => {
          console.log(e)
          setLoader(false)
        })
    },
  })
  const quillRef = useRef()

  return (
    <>
      <CForm className="row g-3" onSubmit={formik.handleSubmit}>
        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Type *
          </CFormLabel>
          <CFormSelect
            aria-label="Default select example"
            name="type"
            className={
              'form-control' + (formik.errors.type && formik.touched.type ? ' is-invalid' : '')
            }
            value={formik.values.type}
            onChange={formik.handleChange}
            id="type"
          >
            <option value={0}>Select type</option>
            <option value={1}>Pricing Feature</option>
            <option value={2}>Rules</option>
            <option value={3}>Faqs</option>
            <option value={4}>How To Play</option>
            <option value={5}>how It Works</option>
            <option value={6}>whats new</option>
          </CFormSelect>
          {formik.errors.type && formik.touched.type && (
            <CFormFeedback invalid>{formik.errors.type}</CFormFeedback>
          )}
        </CCol>
        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="first_name">
            Text1
          </CFormLabel>
          <input
            type="text"
            name="text1"
            className={
              'form-control' + (formik.errors.text1 && formik.touched.text1 ? ' is-invalid' : '')
            }
            id="text1"
            placeholder="text1"
            defaultValue={featureDetail?.text1}
            onChange={formik.handleChange}
          />
          {formik.errors.text1 && formik.touched.text1 && (
            <CFormFeedback invalid>{formik.errors.text1}</CFormFeedback>
          )}
        </CCol>

        <CCol md={6}>
          <CFormLabel className="fw-bold" htmlFor="name">
            Text2
          </CFormLabel>
          <ReactQuill
            theme="snow"
            ref={quillRef}
            value={longDescription}
            onChange={setLongDescription}
          />
        </CCol>
        <CCol md={6}></CCol>
        <CCol md={6}>
          <CLoadingButton type="submit" color="success" variant="outline" loading={loader}>
            Submit
          </CLoadingButton>
        </CCol>
      </CForm>
    </>
  )
}

export default EditForm
